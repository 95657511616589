import React from "react";
import { Table, Alert } from "react-bootstrap";
import _ from "underscore";
import { getAPI } from "utils/requestAPI";
import { csCallMapping } from "utils/common";

class FujisanBacklogStats extends React.PureComponent
{

	constructor(props)
	{
		super(props);
		this.state = {
			overdueCallPriority: null,
			errorMsg: null
		};
	}

	componentDidMount()
	{
		this.fetchBacklogStats();
	}

	fetchBacklogStats = () =>
	{
		getAPI("/admin/vendor/customerService/backlog.json")
			.then(data =>
			{

				const groupedByCallTypeData = _.groupBy(data.overdueCallPriority, "call_type");

				const groupedByCallTypeAndPriorityData = _.mapObject(groupedByCallTypeData, (val) => _.groupBy(val, "priority"));

				this.setState({
					overdueCallPriority: groupedByCallTypeAndPriorityData,
					errorMsg: null
				});

			})
			.catch(e =>
			{
				this.setState({errorMsg: e.message});
			});
	}

	render()
	{
		const { overdueCallPriority, errorMsg } = this.state;

		if(errorMsg)
			return <Alert variant="danger">{errorMsg}</Alert>;

		if(! overdueCallPriority)
			return null;

		const tableRows = [];

		for (let priority = 100; priority >= 0; priority -= 10)
		{
			const row = [priority];

			for (let i = 1; i <= _.keys(csCallMapping).length; i++)
			{
				const callType = csCallMapping[i];

				if(overdueCallPriority[callType] && overdueCallPriority[callType][priority])
					row.push(overdueCallPriority[callType][priority][0].numCalls || 0);
				else
					row.push(0);
			}

			tableRows.push(row);
		}

		const headerRows = [];

		for (let i = 1; i <= _.keys(csCallMapping).length; i++)
			headerRows.push(csCallMapping[i]);


		const generateColour = (priority, numCalls) =>
		{
			const greenValue = priority;

			if(numCalls == 0)
				return null;

			return `rgb(${255 - greenValue}, 255, ${255 - greenValue})`;
		};

		return (
			<>
				<div style={{ display: "flex" }}>
					<div style={{ display: "flex", width: "90px" }}>
						<Table striped responsive bordered size="sm" hover>
							<thead>
								<tr>
									<th>Priority</th>
								</tr>
							</thead>
							<tbody>
								{tableRows.map((row, i) =>
								{
									return <tr key={i}>
										<td className="font-weight-bold">&pound;{row[0]}</td>
									</tr>;
								})}
							</tbody>
						</Table>
					</div>
					<Table striped responsive bordered size="sm" hover>
						<thead>
							<tr>
								{headerRows.map((callType) => <th key={callType}>{callType}</th>)}
							</tr>
						</thead>
						<tbody>
							{tableRows.map((row, i) =>
							{
								const priority = row[0];

								return <tr key={i}>
									{row.map((numCalls, cellI) =>
									{
									// ignore the first cell - this is the priority which is shown in the other table
										if(cellI === 0)
											return null;

										return <td key={cellI} className="text-center" style={{backgroundColor: generateColour(priority, numCalls)}}>{numCalls}</td>;
									})}
								</tr>;
							})}
						</tbody>
					</Table>
				</div>
				<h3 className="mt-4">Good to know:</h3>
				<ul>
					<li>Calls are done in priority order, from high to low (a call 1 is not always done first!)</li>
					<li>The priority is calculated by measuring the live listing value from the last 18 months of calls</li>
					<li>Not all of these calls above will actually need to be done (i.e if the vendor has automised)</li>
				</ul>
			</>

		);
	}
}

export default FujisanBacklogStats;