import React from "react";
import { Form, FormControl, Row, Col, Button, Spinner } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import UserSearch from "../users/userSearch";
import _ from "underscore";
import { postAPI } from "utils/requestAPI";


class NoteInput extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			isAdminGroup: false,
			note: null,
			reminderAdminGroup: null,
			reminderAdminGroupName: "",
			reminderDate: null,
			reminderTime: "09:30",
			reminderUser: this.context ? this.context.GA_CONFIG.user.id : 0,
			reminderUserName: this.context ? this.context.GA_CONFIG.user.name : "name",
			isAdding: false,
		};

		if(this.props.defaultRemindTo)
		{
			// overwrite defaults
			if(this.props.defaultRemindTo.isAdminTeam)
			{
				this.state.reminderAdminGroup = this.props.defaultRemindTo.id;
				this.state.reminderAdminGroupName = this.props.defaultRemindTo.name;
				this.state.isAdminGroup = true;
			}
			else
			{
				this.state.reminderUser = this.props.defaultRemindTo.id;
				this.state.reminderUserName = this.props.defaultRemindTo.name;
			}
		}

		this.alertUser = this.alertUser.bind(this);
	}

	static contextType = GAConfigContext;

	alertUser(e)
	{
		if(this.state.isAdding)
		{
			/*
				This shows a popup so the user can confirm that they want to unload the page.
				See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event for explanation of implementation
			*/
			e.preventDefault();
			e.returnValue = "Your note is still saving!";
		}
	}

	componentDidMount()
	{
		this.setState({
			reminderUser: this.context.GA_CONFIG.user.id,
			reminderUserName: this.context.GA_CONFIG.user.name,
		});
	}

	componentWillUnmount()
	{
		window.removeEventListener("beforeunload", this.alertUser);
	}


	addNote()
	{
		// check valid note
		if((! this.state.note) || (this.state.note.length < 5))
			return alert("The note is too short grrrrr");

		if((this.state.reminderDate !== null) && (! this.state.reminderTime))
			return alert("Please set a reminder time");

		if((this.state.reminderDate !== null) && (this.state.reminderTime) && (! this.state.reminderUser))
			return alert("Please set a reminder user");

		this.setState({ isAdding: true});

		// add "Don't leave me" event at this point
		window.addEventListener("beforeunload", this.alertUser);

		if(this.props.onAddNote)
		{
			this.props.onAddNote(this.state).then(() =>
			{
				this.setState({isAdding: false});
				window.removeEventListener("beforeunload", this.alertUser);
			});
		}
		else
		{
			const { branch, vendorProp, referralProp, gaxCall } = this.props;

			const { note, reminderDate, reminderTime, reminderUser, reminderAdminGroup, isAdminGroup } = this.state;
			const data = {};
			const url = referralProp ? `/admin/referrals/${referralProp.id}/notes`
				: branch ? `/admin/branch/${branch.id}/note`
					: `/admin/vendor/property/${vendorProp.id}/note`;

			data.note = note;

			if(branch)
				data.branch_id = branch.id;

			if(vendorProp)
				data.vendor_prop_id = vendorProp.id;

			if(referralProp)
			{
				data.referral_prop_id = referralProp.id;
				data.callId = gaxCall?.id;
			}

			if(reminderDate && reminderDate != "0")
			{
				data.reminder_date = reminderDate;
				data.reminder_time = reminderTime;

				if(reminderAdminGroup && isAdminGroup)
					data.reminder_team_id = reminderAdminGroup;
				else if(reminderUser)
					data.reminder_user_id = reminderUser;
				else
					data.reminder_user_id = this.context.GA_CONFIG.user.id;
			}

			postAPI(url, data)
				.then(data =>
				{
					this.setState({ note: "", reminderDate: null});

				})
				.catch(e =>
				{

					alert("Error when adding the note!: ", e);
				})
				.finally(() =>
				{
					this.setState({isAdding: false});
					window.removeEventListener("beforeunload", this.alertUser);
				});

			if(this.props.onNoteSubmitted)
				this.props.onNoteSubmitted();
		}

		this.setState({
			reminderDate: null,
			reminderTime: "09:30",
			reminderUser: this.context.GA_CONFIG.user.id,
			note: null
		});

	}

	dateChange(e)
	{
		const change = {reminderDate: e.target.value};

		if(e.target.value === "")
			change.reminderDate = null;

		this.setState(change);

		if(this.props.onChangeNote)
			this.props.onChangeNote(_.extend({}, this.state, change));
	}

	timeChange(e)
	{
		const change = {reminderTime: null};

		if(e.target.value)
			change.reminderTime = e.target.value;

		this.setState(change);

		if(this.props.onChangeNote)
			this.props.onChangeNote(_.extend({}, this.state, change));
	}

	reminderUserChange(userOrAdminTeam)
	{
		const change = {reminderUser: this.state.reminderUser};

		if(userOrAdminTeam && userOrAdminTeam.id && !("adminGroup" in userOrAdminTeam))
		{
			change.reminderUser = userOrAdminTeam.id;
			change.isAdminGroup = false;
		}
		else if(userOrAdminTeam && userOrAdminTeam.id && "adminGroup" in userOrAdminTeam && userOrAdminTeam.adminGroup) // add in caveat for admin teams
		{
			change.reminderAdminGroup = userOrAdminTeam.id;
			change.isAdminGroup = true;
		}

		this.setState(change);

		if(this.props.onChangeNote)
			this.props.onChangeNote(_.extend({}, this.state, change));
	}

	noteChange(e)
	{
		if(this.props.onNoteChange)
			this.props.onNoteChange(e.target.value);

		const change = {note: e.target.value};

		this.setState(change);

		if(this.props.onChangeNote)
			this.props.onChangeNote(_.extend({}, this.state, change));
	}

	render()
	{
		const autoCallerTickBox = () =>
		{
			if(!this.props.optionToStopAutoCaller)
				return null;

			return (
				<Form.Group controlId="doNotMakeNextCall">
					<Form.Check type="checkbox" checked={this.props.isChecked} onChange={this.props.autoCallerHandler} label="Do not make next call?" />
				</Form.Group>
			);
		};

		const reminder = () =>
		{
			if(! this.props.allowReminder)
				return null;

			const {
				isAdminGroup,
				reminderUserName,
				reminderAdminGroupName
			} = this.state;

			const defaultName = isAdminGroup ? reminderAdminGroupName : reminderUserName;

			return (
				<Row>
					<Col xs="12">
						<Form.Text>{this.props.reminderTitle ? this.props.reminderTitle : "Reminder :"}</Form.Text>
					</Col>
					<Col lg={this.props.csView ? null: "8"} xs={this.props.csView ? "12" :null}>
						<FormControl
							type="date"
							onChange={this.dateChange.bind(this)}
							value={this.state.reminderDate || ""}
						/>
					</Col>
					{this.state.reminderDate ? (
						<Col lg={this.props.csView ? null: "4"} xs={this.props.csView ? "12" :null} className="mt-2 mt-lg-0">
							<FormControl as="select" placeholder="select" value={this.state.reminderTime} onChange={this.timeChange.bind(this)}>
								{_.range(0,48).map(i =>
								{
									if((i % 4) === 0)
										return (<option value={(i/4) + 8 + ":00"} key={i}>{(i/4) + 8}:00</option>);
									else if((i % 4) === 1)
										return (<option value={Math.floor(i/4) + 8 + ":15"} key={i}>{Math.floor(i/4) + 8}:15</option>);
									else if((i % 4) === 2)
										return (<option value={Math.floor(i/4) + 8 + ":30"} key={i}>{Math.floor(i/4) + 8}:30</option>);
									else
										return (<option value={Math.floor(i/4) + 8 + ":45"} key={i}>{Math.floor(i/4) + 8}:45</option>);
								})}
							</FormControl>
						</Col> ) : null}

					{(this.state.reminderDate && !this.props.csView) ? (
						<Col xs="12" className="mt-2">
							<UserSearch
								adminOnly
								includeAdminGroups
								placeholder="Who to remind?"
								onValueChanged={this.reminderUserChange.bind(this)}
								value={defaultName}
							/>
						</Col>
					) : null}
				</Row>);
		};

		return (
			<div className={this.props.className}>
				<Row>
					<Col md={this.props.fullWidth ? "12" : "7"}>
						<FormControl
							as="textarea"
							placeholder="Your note goes here"
							rows="5"
							value={this.state.note || ""}
							onChange={this.noteChange.bind(this)}
						/>
					</Col>
					<Col md={this.props.fullWidth ? "12" : this.props.hideAddButton ? 5 : 3} className={this.props.fullWidth ? "mt-3" : "mt-2 mt-md-0"}>
						{reminder()}
					</Col>
					{! this.props.hideAddButton && (
						<Col md={this.props.fullWidth ? "12" :"2"} className={this.props.fullWidth ? "mt-3" : "mt-3 mt-md-0"}>
							<Button
								variant="primary"
								size="large"
								block
								disabled={this.state.isAdding}
								onClick={this.addNote.bind(this)}
							>{this.state.isAdding ?
									<span>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
										/> Adding...
									</span> : "Add a note"
								}
							</Button>
						</Col>
					)}
				</Row>
				<Row>
					<Col md="12">
						{this.props.optionToStopAutoCaller ? autoCallerTickBox() : null}
					</Col>
				</Row>
			</div>
		);


	}
}

export default NoteInput;
