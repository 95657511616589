import React, { useState, useMemo, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";

const hours = ["09","10","11","12","13","14","15","16","17"];

const minutes = ["00","15","30","45"];

const BdCrmPanelDateTime = ({ title, onChange }) =>
{
	const [date, setDate] = useState(null);
	const [hour, setHour] = useState(null);
	const [minute, setMinute] = useState(null);

	const dateTime = useMemo(() =>
	{
		if(date && hour && minute)
			return `${date} ${hour}:${minute}:00`;
		else
			return null;
	}, [date, minute, hour]);

	useEffect(() =>
	{
		onChange(dateTime);
	}, [dateTime]);

	return (
		<>
			<Form.Label className="mt-3"><b>{title}:</b></Form.Label>
			<Row>
				<Col>
					<Form.Control
						type="date"
						onChange={(e) => setDate(e.target.value)}
					/>
				</Col>
				<Col>
					<Row>
						<Col>
							<Form.Control
								as="select"
								value={hour ?? ""}
								onChange={e => setHour(e.target.value)}
							>
								<option value="" disabled hidden>Hour</option>
								{hours.map(e => <option key={`${e}-hr`} value={e}>{e}</option>)}
							</Form.Control>
						</Col>
						<span style={{
							display: "flex",
							alignItems: "center"
						}}>:</span>
						<Col>
							<Form.Control
								as="select"
								value={minute ?? ""}
								onChange={e => setMinute(e.target.value)}
							>
								<option value="" disabled hidden>Minute</option>
								{minutes.map(e => <option key={`${e}-min`} value={e}>{e}</option>)}
							</Form.Control>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default BdCrmPanelDateTime;