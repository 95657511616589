import React, { useState } from "react";
import {Card, Row, Col, FormLabel, FormGroup, Form, Button} from "react-bootstrap";
import EditableText from "components/shared/editableText";
import EditableSelect from "components/shared/editableSelect";
import {FaEnvelope} from "react-icons/fa";
import PhoneNumber from "awesome-phonenumber";
import { gaNiceFormatPrice } from "utils/common";

const CallCard = ({referralProp, addresses = [], saveReferralProp, addressConfirmed, fetchAddress }) =>
{
	const [editing, setEditing] = useState(true);
	const [editData, setEditData] = useState(referralProp);
	const { name, email, bedrooms, estvalue, mobile, address, postcode } = referralProp;

	const generateShortlist = () =>
	{
		const resp = confirm("Are you sure you want to generate the shortlist with these details?");

		if(resp)
		{
			let shortlistUrl = process.env.REACT_APP_PUBLIC_URL_BASEPATH;

			shortlistUrl += `/exchange/${referralProp.referrer_id}/referral-lead/${referralProp.id}`;
			shortlistUrl += `?postcode=${postcode}&bedrooms=${bedrooms}&estvalue=${estvalue}`;

			window.open(shortlistUrl, "_blank");
		}
	};

	const updateEditData = (updateObj) =>
	{
		setEditData({...editData, ...updateObj});
	};

	const isMobileValid = () =>
	{
		if(!editData.mobile)
			return false;

		try
		{
			const phoneNumber = new PhoneNumber(editData.mobile, "GB");

			return phoneNumber.isValid();
		}
		catch (err)
		{
			return false;
		}
	};

	const isEmailValid = () =>
	{
		if(!editData.email)
			return false;

		const isEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return isEmail.test(editData.email.trim());
	};

	const isEstValueValid = () =>
	{
		if(!editData.estvalue)
			return false;

		return parseInt(editData.estvalue) > 0;
	};

	const isNumberValid = (value, min = 1, max) =>
	{
		if(!value)
			return false;

		return parseInt(value) >= min && (!max || parseInt(value) <= max);
	};

	const isValidPostcode = () =>
	{
		if(!editData.postcode)
			return false;

		const validPostcodeReg = new RegExp("^(([A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1})[ ]*([0-9]{1}[A-Z]{2}))$", "i");

		return editData.postcode.match(validPostcodeReg);
	};

	const valuesNotEmpty = (values) =>
	{
		const errors = values.filter(v => !v || typeof v === "undefined");

		return errors.length;
	};

	const updateReferral = () =>
	{
		if(
			!isEmailValid()
			|| !isMobileValid()
			|| !isNumberValid(editData.estvalue)
			|| !isNumberValid(editData.bedrooms, 1, 5)
			|| !isValidPostcode()
			|| valuesNotEmpty([
				editData.name,
				editData.address,
				editData.postcode,
				editData.bedrooms,
			])
		)
		{
			alert("Please fix all errors");
			return false;
		}

		if(!editData.address || editData.address === referralProp.address)
			delete editData.postcode;

		setEditing(false);
		saveReferralProp(editData, true);
	};

	const createAddress = (addressObj) =>
	{
		let addr = addressObj.line_1;

		if(addressObj.line_2)
			addr += `, ${addressObj.line_2}`;

		if(addressObj.line_3)
			addr += `, ${addressObj.line_3}`;

		return addr;
	};

	return (
		<>
			<Card>
				<Card.Header>
					{"Referral details - " + referralProp.id}
				</Card.Header>
				<Card.Body>
					<Form>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Name:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableText
											value={editing ? editData.name : name}
											editing={editing}
											onValueChanged={(e) => updateEditData({ name: e.target.value })}
											validationState={valuesNotEmpty([editData.name])}

										/>
									</Col>
								</FormGroup>
							</Col>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Email:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableText
											value={editing ? editData.email : email}
											type="email"
											editing={editing}
											onValueChanged={(e) => updateEditData({ email: e.target.value })}
											icon={<FaEnvelope />}
											validationState={isEmailValid() ?  null : "error"}
											href={"mailto:" + email}
										/>
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Postcode:</FormLabel>
									</Col>
									<Row className="m-0">
										<Col sm={6}>
											<EditableText
												value={editing ? editData.postcode : postcode}
												editing={editing}
												onValueChanged={(e) => updateEditData({postcode: e.target.value})}
												validationState={!isValidPostcode()}
											/>
										</Col>
										<Col sm={4} className="p-0">
											{editing ? <Button onClick={() => {
												if(isValidPostcode)
													fetchAddress(editData.postcode);
											}}>Update</Button> : null}
										</Col>
									</Row>
								</FormGroup>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Address: {!referralProp.udprn && `(${referralProp.address})`}</FormLabel>
									</Col>
									<Col sm={8}>
										{editing ?
											<Form.Control
												as="select"
												value={editData.udprn || "manual-" + address?.address1}
												onChange={(e) => updateEditData({
													udprn: e.target.value,
													address: e.target.options[e.target.selectedIndex].text
												})}
												validationState={valuesNotEmpty([editData.address])}
											>
												<option value={"-"}>
													Select Address
												</option>
												{
													addresses.map((add, i) =>
														<option
															key={i}
															value={add.udprn || "manual-" + add.address1}
															style={{backgroundColor: add.udprn ? null : "red"}}
														>
															{createAddress(add)}
															{add.udprn ? null : "(manual)"}
														</option>
													)
												}
												<option
													value="address-not-listed"
													style={{backgroundColor: "yellow"}}
												>
												Address not listed?
												</option>
											</Form.Control>
											: <div>{address}</div>
										}
									</Col>
								</FormGroup>
							</Col>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Bedrooms:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableSelect
											value={editing ? editData.bedrooms : bedrooms}
											options={{0: "Select bedrooms", 1: "1 bed or studio", 2: "2 bedrooms", 3: "3 bedrooms", 4: "4 bedrooms", 5: "5+ bedrooms"}}
											onValueChanged={(e) => updateEditData({ bedrooms: e.target.value })}
											validationState={valuesNotEmpty([editData.bedrooms])}
											editing={editing} >
										</EditableSelect>
									</Col>
								</FormGroup>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Est. value:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableText
											value={editing ? editData.estvalue : estvalue}
											editing={editing}
											formatter={(val) => "£" +gaNiceFormatPrice(val)}
											onValueChanged={(e) => updateEditData({ estvalue: e.target.value })}
											validationState={isEstValueValid() ?  null : "error"}
											type="number"
										/>
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup className={isNumberValid() ? null : "has-error"}>
									<Col sm={4}>
										<FormLabel>Phone:</FormLabel>
									</Col>
									<Col sm={8}>
										{editing ? (
											<EditableText
												value={editing ? editData.mobile : mobile}
												type="text"
												editing={editing}
												onValueChanged={(e) => updateEditData({ mobile: e.target.value })}
												icon="phone"
												openLinkInWindow={{name: "callWin", size:"width=500,height=500"}}
												validationState={isMobileValid() ? null : "error"}
											/>):(

											<span>
												{mobile} &nbsp;
											</span>
										)}

									</Col>
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</Card.Body>
				<Card.Footer>
					<Row>
						<Col xs={12} md={6}>
							{editing ? (
								<>
									<Button onClick={() =>
									{
										setEditData(referralProp);
										setEditing(false);
									}}>Cancel</Button>

									<Button className="float-right" onClick={updateReferral}>Save</Button>
								</>
							) : (
								<Button onClick={() =>
								{
									setEditData(referralProp);
									setEditing(true);
								}}>Edit</Button>
							)}
						</Col>
						<Col xs={12} md={6}>
							<Button onClick={generateShortlist} disabled={!addressConfirmed} className="float-right">Generate Shortlist</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</>
	);
};

export default CallCard;