import React, {useState, useContext, useEffect} from "react";
import { getAPI, postAPI } from "utils/requestAPI";
import { Promise } from "bluebird";
import Caller from "../call-form/caller";
import {Card, Row, Col, Form, Button } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import moment from "moment";
import NoteInput from "components/shared/noteInput";
import BdCrmPanelDateTime from "components/bd-crm/bdCrmPanelDateTime";

const CallDetails = ({referralProp = {}, gaxCall, fetchData, referrer}) =>
{
	const [loading, setLoading] = useState(true);
	const [outcome, setOutcome] = useState(null);
	const [whyNoVals, setWhyNoVals] = useState(null);
	const [callBackTime, setCallBackTime] = useState(false);
	const [callStatus, setCallStatus] = useState();
	const [twilioToken, setTwilioToken] = useState(null);
	const [callerReady, setCallerReady] = useState(false);
	const [clientCallId, setClientCallId] = useState(null);
	const [startCall, setStartCall] = useState(false);
	const [isNoteFilled, setIsNoteFilled] = useState(false);

	const { GA_CONFIG } = useContext(GAConfigContext);

	useEffect(() =>
	{
		setLoading(true);
		setOutcome(GA_CONFIG.gaxCalls.outcomes[0]);

		Promise.join(fetchTwilioToken())
			.then(() => setLoading(false));
	}, []);


	const fetchTwilioToken = () =>
	{
		return getAPI("/admin/twilioToken.json")
			.then(({ twilioToken }) => setTwilioToken(twilioToken))
			.catch(e => alert(e || "An error occurred."));
	};

	const handleOnCallEnd = async() =>
	{
		setStartCall(false);
		setCallerReady(true);

		setCallStatus("ENDED");

		if(clientCallId)
		{
			try
			{
				await postAPI(`/admin/gaxCalls/${gaxCall.id}/done`, { clientCallId: clientCallId });
			}
			catch (err)
			{
				alert("Error while updating Gax call details after end of call: " + err.message);
			}
		}
	};

	const handleStartCall = () =>
	{
		if(referralProp.mobile)
		{
			setStartCall(true);
			setCallerReady(false);
			setCallStatus("IN_PROGRESS");
		}
		else
		{
			alert("No number to call...");
		}
	};

	const handleSaveGaxCall = async() =>
	{
		if(isNoteFilled)
		{
			alert("There is an unsaved note");
			return;
		}

		try
		{
			await postAPI(`/admin/gaxCalls/${gaxCall.id}/outcome`, {
				outcome: outcome,
				whyNoVals: whyNoVals,
				callAt: callBackTime
			});
		}
		catch (err)
		{
			alert("Error while updating outcome: " + err.message);
		}

		setCallStatus("SAVED");
	};

	const handleNoteChange = (e) =>
	{
		setIsNoteFilled(!!e);
	};

	const handleNoteSubmit = () =>
	{
		setIsNoteFilled(false);
	};

	if(loading)
		return <div>Loading</div>;

	return (
		<Card>
			<Card.Header>
				<Row>
					<Col xs={12} md={6}>
						<p>Next call: {gaxCall.call_type}</p>
					</Col>
					<Col xs={12} md={6}>
						Referrer: {referrer.name} {referralProp.lead_info?.branch ? ` - (${referralProp.lead_info?.branch})` : null}
						{referralProp.lead_info?.notes ? (
							<>
								<br/>
								Notes: {referralProp.lead_info.notes}
							</>
						) : null}
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col xs={12} md={6}>
						<Caller
							phoneNum={referralProp.mobile}
							callerReady={(ready) => setCallerReady(ready)}
							twilioToken={twilioToken}
							startCall={startCall}
							onCallEnd={handleOnCallEnd}
							setCallClientId={setClientCallId}
						/>
						{callerReady && (
							<Button onClick={handleStartCall}>Make the call</Button>
						)}
					</Col>
					<Col xs={12} md={6}>
						{callStatus && (
							<div className="mb-4">
								<Form.Label>
										Add Note:
								</Form.Label>
								<NoteInput
									referralProp={referralProp}
									gaxCall={gaxCall}
									allowReminder={false}
									fullWidth
									onNoteChange={handleNoteChange}
									onNoteSubmitted={handleNoteSubmit}
								/>
							</div>
						)}
						{callStatus === "ENDED" && (
							<Form.Group>
								<Form.Label>
										Call Outcome:
								</Form.Label>
								<Form.Control
									as="select"
									value={outcome}
									onChange={(e) => setOutcome(e.target.value)}
								>
									{GA_CONFIG.gaxCalls.outcomes.map(o =>
										<option key={o} value={o}>{o}</option>
									)}
								</Form.Control>
								{outcome === "ANSWERED_NO_VALS" &&
									<>
										<Form.Label>
												Outcome Reason:
										</Form.Label>
										<Form.Control
											as="select"
											value={whyNoVals || GA_CONFIG.gaxCalls.reasons[0]}
											onChange={(e) => setWhyNoVals(e.target.value)}
										>
											<option value="-">Select Reason</option>
											{GA_CONFIG.gaxCalls.reasons.map(o =>
												<option key={o} value={o}>{o}</option>
											)}
										</Form.Control>
									</>
								}
								{outcome === "ANSWERED_CALL_BACK" &&
									<>
										<BdCrmPanelDateTime title="Call Back Time" onChange={(e) => setCallBackTime(moment(e).format("YYYY-MM-DDTHH:mm"))} />
									</>
								}
								<Button onClick={handleSaveGaxCall} className="mt-4">Update Call</Button>
							</Form.Group>
						)}
						{callStatus === "SAVED" && (
							<Button onClick={() =>
							{
								setCallStatus(null);
								fetchData();
							}}>Next call!</Button>
						)}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default CallDetails;