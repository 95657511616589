import React, { useState, useEffect } from "react";
import { Spinner,  Button} from "react-bootstrap";
import { getAPI, postAPI } from "utils/requestAPI";
import CallCard from "./CallCard";
import CallDetails from "./CallDetails";

const GaxLeads = () =>
{
	const [startCall, setStartCall] = useState(false);
	const [noCalls, setNoCalls] = useState(false);
	const [loading, setLoading] = useState(false);
	const [gaxCall, setGaxCall] = useState(null);
	const [referralProp, setReferralProp] = useState(null);
	const [addressConfirmed, setAddressConfirmed] = useState(false);
	const [addresses, setAddresses] = useState([]);
	const [referrer, setReferrer] = useState(null);

	useEffect(() =>
	{
		if(startCall)
			fetchData();
	}, [startCall]);

	const updateReferralProp = (updateObj) =>
	{
		setReferralProp({...referralProp, ...updateObj});
	};

	const saveReferralProp = async(updateObj, confirm) =>
	{
		if(confirm)
			setAddressConfirmed(true);

		try
		{
			await postAPI(`/admin/referrals/${referralProp.id}/update`, {...updateObj});
		}
		catch (err)
		{
			alert("Error while updating Gax call details after end of call: " + err.message);
		}

		fetchData(true);
	};

	const fetchData = async(noload) =>
	{
		if(!noload)
			setLoading(true);

		const resp = await getAPI("/admin/gaxCalls/next", {skipValidate: true});

		if(resp.error && resp.type === "NO_CALLS_AVAILABLE")
			return setNoCalls(true);

		let { gaxCall } = resp;

		setGaxCall(gaxCall);
		setReferralProp(gaxCall.referralProp);
		setReferrer(gaxCall.referralProp.referrer);
		fetchAddress(gaxCall.referralProp.postcode);
		setLoading(false);
	};

	const fetchAddress = async(postcode) =>
	{
		try
		{
			const addresses = await getAPI(`/address/list?postcode=${postcode}`);

			setAddresses(addresses.result);
		}
		catch (e)
		{
			console.log("ERROR: ", e);
		}
	};

	if(!startCall)
	{
		return (
			<div className="text-center mt-5">
				<h2 className="text-center my-5">Welcome to the GetAgent Exchange View</h2>
				<Button onClick={() => setStartCall(true)} className="ml-auto mr-auto">Start Gax Call</Button>
			</div>
		);
	}

	if(noCalls)
		return <div className="text-center mt-5">There are no Gax calls to make!</div>;

	if(loading)
	{
		return (
			<div className="text-center mt-5">
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>
		);
	}

	return (
		<>
			<div  style={{ maxWidth: 1800 }}>
				<div className="page-header mt-5">
					<h1> GAX Leads </h1>
				</div>
				{gaxCall ? (
					<div className="pb-4">
						<CallDetails
							gaxCall={gaxCall}
							fetchData={fetchData}
							referralProp={referralProp}
							referrer={referrer}
						/>
						<div style={{paddingTop: "8px"}}>
							<CallCard
								referralProp={referralProp}
								addresses={addresses}
								updateReferralProp={updateReferralProp}
								saveReferralProp={saveReferralProp}
								addressConfirmed={addressConfirmed}
								fetchAddress={fetchAddress}
							/>
						</div>
					</div>
				) : (
					<div>
						<h2>No calls!</h2>
						<p>There are no current calls for you to make.</p>
					</div>
				)}
			</div>
		</>
	);
};

export default GaxLeads;