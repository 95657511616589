import React, { useState, useContext } from "react";
import { GAConfigContext } from "contexts/gaConfigContext";
import { Button } from "react-bootstrap";
import { postAPI } from "utils/requestAPI";

const AgentCallTransfer = ({
	branchId,
	vendorPropId,
	clientCallId
}) =>
{
	const { GA_CONFIG: { user } } = useContext(GAConfigContext);

	const [isTransferrable, setIsTransferrable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const transfer = async() =>
	{
		setIsLoading(true);

		try
		{
			const data = {
				parentSid: clientCallId,
				vpId: vendorPropId,
				uId: user.id,
				bId: branchId
			};

			const res = await postAPI(
				"/call/from-browser/agent-transfer/start?preflight=" + (!isTransferrable ? "1" : "0"), data);

			if(res.success)
			{
				setError(null);
				setIsTransferrable(true);
			}
		}
		catch (err)
		{
			setIsTransferrable(false);
			setError(err.name === "Error" ? err.message : "An unknown error occurred. Transfer not possible");
		}

		setIsLoading(false);
	};

	const getButtonText = () =>
	{
		if(isLoading && !isTransferrable)
			return "Checking transfer eligibility...";

		if(isLoading && isTransferrable)
			return "Transferring...";

		if(isTransferrable)
			return "Transfer";

		return "Check transfer eligibility";
	};

	return (
		<div className="pt-2">
			<Button
				className="w-100"
				disabled={isLoading}
				variant={isTransferrable ? "success" : "danger"}
				onClick={transfer}
			>
				{getButtonText()}
			</Button>
			<div>{error}</div>
		</div>
	);
};

export { AgentCallTransfer };
